import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        <script type="text/plain" class="optanon-category-4" 
          dangerouslySetInnerHTML={{
            __html:
              "(function (d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s);\njs.id = id;\njs.src = \"//connect.facebook.net/pl_PL/all.js#xfbml=1&appId=654267651256301\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));"
          }}
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/site/framework/css/CheckboxSetField.css?m=1464882010"
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: "\nvar ThemeDir = 'themes/simple';\n"
          }}
        />
        
        <div className="addbg">
          <div className="wrapper produkty">
            <header
              style={{
                height: "120px"
              }}
            >
              <div
                style={{
                  height: "67px"
                }}
              >
                <h1 className="logo">
                  <a href="/" />
                </h1>
                <nav>
                  <a href="/site/produkty/" className="current">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/site/o-neutrogenie/kim-jestesmy/" className="link">
                    O Neutrogenie
                  </a>
                  <span>| </span>
                  <a href="/site/bad-piekna/" className="link">
                    Bądź piękna
                  </a>
                  <span>| </span>
                  <a href="/site/see-whats-possible/" className="link">
                    See What's Possible
                  </a>
                  <span>| </span>
                  <a href="/site/skora-z-niedoskonalosciami/" className="link">
                    Skóra z niedoskonałościami
                  </a>
                  <span>| </span>
                </nav>
              </div>
              <div
                style={{
                  "padding-left": "185px",
                  height: "53px"
                }}
              >
                <nav
                  className="second"
                  style={{
                    float: "none"
                  }}
                >
                  <a href="/site/hydroboost/" className="link">
                    Hydro Boost
                  </a>
                  <span>| </span>
                  <a href="/site/skin-detox/" className="link">
                    Skin Detox
                  </a>
                  <span>| </span>
                  <a href="/site/cellular-boost/" className="link">
                    Cellular Boost
                  </a>
                  <span />
                </nav>
              </div>
            </header>
            <div className="top">
              <h2>
                Produkty
                <br />
                NEUTROGENA <span>®</span>
              </h2>
              <h3>
                Wybierz produkt dostosowany do Twoich potrzeb, <br />
                korzystając z poniższych opcji.
              </h3>
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                Produkty
              </div>
            </div>
          
            <div className="content">
              <div className="filters">
                <p className="head">
                  Filtrowanie
                  <span />
                </p>
                <div className="filtrate">
                  <div className="middle">
                    <div className="aboutAnchor" />
                    <div>
                      
                        <p
                          id="Form_ProductsFilter_error"
                          className="message "
                          style={{
                            display: "none"
                          }}
                        />
                        <fieldset>
                          <div className="CompositeField composite nolabel ">
                            <div className="CompositeField composite nolabel ">
                              <div
                                id="Nice0"
                                className="field optionset checkboxset"
                              >
                                <label
                                  className="left"
                                  htmlFor="Form_ProductsFilter_Nice0"
                                >
                                  Przeznaczenie
                                </label>
                                <div className="middleColumn">
                                  <ul
                                    id="Form_ProductsFilter_Nice0"
                                    className="optionset checkboxset"
                                  >
                                    <li className="odd val6">
                                    <a htmlFor="Form_ProductsFilter_Nice0_6"
                                        className='custom-label' href='/produkty/do-ciała'>
                                        <u>Do ciała</u>
                                      </a>
                                    </li>
                                    <li className="even val7">
                                    <a htmlFor="Form_ProductsFilter_Nice0_7"
                                        className='custom-label' href='/produkty/do-rąk'>
                                        Do rąk
                                      </a>
                                    </li>
                                    <li className="odd val8">
                                    <a htmlFor="Form_ProductsFilter_Nice0_8"
                                        className='custom-label' href='/produkty/do-stóp'>
                                        Do stóp
                                      </a>
                                    </li>
                                    <li className="even val9">
                                    <a htmlFor="Form_ProductsFilter_Nice0_9"
                                        className='custom-label' href='/produkty/do-twarzy-i-ust'>
                                        Do twarzy i ust
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="CompositeField composite nolabel ">
                            <div className="CompositeField composite nolabel ">
                              <div
                                id="Nice1"
                                className="field optionset checkboxset"
                              >
                                <label
                                  className="left"
                                  htmlFor="Form_ProductsFilter_Nice1"
                                >
                                  Typ skóry
                                </label>
                                <div className="middleColumn">
                                  <ul
                                    id="Form_ProductsFilter_Nice1"
                                    className="optionset checkboxset"
                                  >
                                    <li className="odd val14">
                                    <a htmlFor="Form_ProductsFilter_Nice1_14"
                                        className='custom-label' href='/produkty/bardzo-sucha'>
                                        Bardzo sucha/szorstka{" "}
                                      </a>
                                    </li>
                                    <li className="even val15">
                                    <a htmlFor="Form_ProductsFilter_Nice1_15"
                                        className='custom-label' href='/produkty/sucha'>
                                        Sucha
                                      </a>
                                    </li>
                                    <li className="odd val16">
                                    <a htmlFor="Form_ProductsFilter_Nice1_16"
                                        className='custom-label' href='/produkty/normalna'>
                                        Normalna
                                      </a>
                                    </li>
                                    <li className="even val17">
                                    <a htmlFor="Form_ProductsFilter_Nice1_17"
                                        className='custom-label' href='/produkty/wrażliwa'>
                                        Wrażliwa
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="CompositeField composite nolabel ">
                            <div className="CompositeField composite nolabel ">
                              <div
                                id="Nice2"
                                className="field optionset checkboxset"
                              >
                                <label
                                  className="left"
                                  htmlFor="Form_ProductsFilter_Nice2"
                                >
                                  Zastosowanie
                                </label>
                                <div className="middleColumn">
                                  <ul
                                    id="Form_ProductsFilter_Nice2"
                                    className="optionset checkboxset"
                                  >
                                    <li className="odd val18">
                                    <a htmlFor="Form_ProductsFilter_Nice2_18"
                                        className='custom-label' href='/produkty/odżywianie'>
                                        Odżywianie
                                      </a>
                                    </li>
                                    <li className="even val19">
                                    <a htmlFor="Form_ProductsFilter_Nice2_19"
                                        className='custom-label' href='/produkty/oczyszczanie'>
                                        Oczyszczanie/ Nawilżanie
                                      </a>
                                    </li>
                                    <li className="odd val20">
                                    <a htmlFor="Form_ProductsFilter_Nice2_20"
                                        className='custom-label' href='/produkty/regeneracja'>
                                        Regeneracja
                                      </a>
                                    </li>
                                    <li className="even val69">
                                    <a htmlFor="Form_ProductsFilter_Nice2_69"
                                        className='custom-label' href='/produkty/elastyczność'>
                                        Elastyczność
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <input
                            type="hidden"
                            name="SecurityID"
                            defaultValue="9dc27fd9db0485813fdb68e79c9d70ac994d4fae"
                            className="hidden"
                            id="Form_ProductsFilter_SecurityID"
                          />
                          <div className="clear" />
                        </fieldset>                        
                    </div>
                  </div>
                </div>
              </div>            
              <div className="products">
                <div className="row">   
                <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/9/"
                  >
                    <img
                      src="/site/assets/Uploads/NTGUK-3574661017365-Deep-Moisture-Body-Lotion-Dry-and-Sensitive-Skin.0000.png"
                      alt="NTGUK 3574661017365 Deep Moisture Body Lotion Dry and Sensitive Skin.0000"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Głęboko
                        nawilżająca emulsja do ciała SENSITIVE
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>               
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/11/"
                  >
                    <img src="/site/assets/Uploads/36.png" alt="36" />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Intensywnie
                        Regenerująca emulsja do ciała
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a> 
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/12/"
                  >
                    <img src="/site/assets/Uploads/37.png" alt="37" />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Intensywnie
                        Regenerująca emulsja do ciała SENSITIVE
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>  
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/13/"
                  >
                    <img src="/site/assets/Uploads/38.png" alt="38" />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Odżywcza emulsja
                        do ciała z maliną nordycką
                      </p>
                      <p>&nbsp;</p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a> 
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/14/"
                  >
                    <img
                      src="/site/assets/Uploads/Packshoty-200-ml/balsam-z-malina-nordycka-200ml-2.png"
                      alt="balsam z malina nordycka 200ml 2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Odżywczy balsam
                        z maliną nordycką
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>  
                  </div>
                  <div className="row">  
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/15/"
                  >
                    <img
                      src="/site/assets/Uploads/NTG-3574660143843-Norwegian-Formula-Deep-Moisture-Comfort-Balm-200ml-00000-2.png"
                      alt="NTG 3574660143843 Norwegian Formula Deep Moisture Comfort Balm 200ml 00000 2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Głęboko
                        nawilżający balsam do ciała i twarzy
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/16/"
                  >
                    <img
                      src="/site/assets/Uploads/Packshoty-200-ml/intensywnie-odzywczy-balsam-200ml-2.png"
                      alt="intensywnie odzywczy balsam 200ml 2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Intensywnie
                        odżywczy balsam
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>  
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/18/"
                  >
                    <img
                      src="/site/assets/Uploads/Packshoty-200-ml/intensywnie-regenerujacy-balsam-200ml-2.png"
                      alt="intensywnie regenerujacy balsam 200ml 2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Intensywnie
                        regenerujący balsam do ciała
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/40/"
                  >
                    <img
                      src="/site/assets/Uploads/NTGUK-3574661017419-Deep-Moisture-Body-Lotion-Dry-Skin.0000.png"
                      alt="NTGUK 3574661017419 Deep Moisture Body Lotion Dry Skin.0000"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Głęboko
                        nawilżająca emulsja do ciała
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/43/"
                  >
                    <img
                      src="/site/assets/Uploads/visibly-renew-emulsja-do-ciala-3574661199283-PL.JPG"
                      alt="visibly renew emulsja do ciala 3574661199283 PL"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup>&nbsp; Formuła Norweska Visibly
                        Renew Elasticity Boost - emulsja do ciała
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  </div>
                  <div className="row">  
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/44/"
                  >
                    <img
                      src="/site/assets/Uploads/Packshoty-200-ml/balsam-do-ciala-visibly-renew.png"
                      alt="balsam do ciala visibly renew"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Visibly Renew
                        Elasticity Boost - balsam do ciała
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/50/"
                  >
                    <img
                      src="/site/assets/Uploads/NTGUK-3574661261584-Deep-Moisture-Oil-in-Lotion.0000.png"
                      alt="NTGUK 3574661261584 Deep Moisture Oil in Lotion.0000"
                    />
                    <span className="txt">
                      <p>Nawilżająca emulsja do ciała z olejkiem sezamowym</p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/62/"
                  >
                    <img
                      src="/site/assets/Uploads/NF-Serum-Flattened-200ml2.jpg"
                      alt="NF Serum Flattened 200ml2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup>&nbsp; Formuła Norweska Visibly
                        Renew Upiększające serum do ciała
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/77/"
                  >
                    <img
                      src="/site/assets/Uploads/HydroBoost-Body-gel-250.png"
                      alt="HydroBoost Body gel 250"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> HydroBoost<sup>®</sup> Żelowy
                        Balsam do ciała
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>    
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/78/"
                  >
                    <img
                      src="/site/assets/Uploads/HydroBoost-spray.png"
                      alt="HydroBoost spray"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> HydroBoost<sup>®</sup> Ekspresowy
                        spray do ciała
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>              
                  </div><div className="row">                            
                              <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/79/"
                  >
                    <img
                      src="/site/assets/Uploads/HydroBoost-whipped-balm.png"
                      alt="HydroBoost whipped balm"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> HydroBoost<sup>®</sup> Aksamitny
                        mus do ciała
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>                  
                </div>
              </div>
            </div>
            <div id="social_id_15"></div>
          </div>
        </div>
        <footer>
          <div className="wrapper">
            <nav>
              <a href="/site/" className="logo2" />
              <a href="/site/nota-prawna/">Nota Prawna</a>
              <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
               <a href="/site/cookies/">Polityka cookies</a>
              <a href="/site/kontakt/">Zachęcamy do kontaktu</a>
                           <span>© Johnson &amp; Johnson Poland Sp. z o.o. 2013 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
            <p>
              Strona zarządzana jest przez Johnson &amp; Johnson Poland Sp. z o.
              o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w
              Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy
              Krajowego Rejestru Sądowego, pod numerem KRS 00000322278, NIP
              113-00-20-467, wysokość kapitału zakładowego: 39.751.500 PLN.
              Johnson &amp; Johnson Poland Sp. z o. o. ponosi wyłączną
              odpowiedzialność za treści zawarte na niniejszej stronie.
              <br /> Strona jest przeznaczona dla użytkowników z Polski.
              Ostatnia aktualizacja: 25/10/2013
              <br />{" "}
            </p>{" "}
            <p>
              <span>
                Strona została zoptymalizowana pod kątem przeglądarek: Internet
                Explorer 7 i wyższych, Mozilla Firefox, Google Chrome, Opera,
                Safari
              </span>{" "}
              <span className="right">
                Zalecana rozdzielczość: 1024 x 768 pikseli
              </span>{" "}
            </p>
          </div>
        </footer>
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
          }}
        />
        
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
          }}
        />
        
      </div>
    );
  }
}

export default Page;